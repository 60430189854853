<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="marketCodeBatchInfo"
                    label="批次信息"
                >
                    <el-input
                        v-model="queryFormModel.marketCodeBatchInfo"
                        placeholder="请输入批次信息"
                    />
                </el-form-item>
                <el-form-item
                    prop="activityName"
                    label="活动名称"
                >
                    <el-input
                        v-model="queryFormModel.activityName"
                        placeholder="请输入活动名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="productBrand"
                    label="商品品牌"
                >
                    <el-input
                        v-model="queryFormModel.productBrand"
                        placeholder="请输入商品品牌"
                    />
                </el-form-item>
                <el-form-item
                    prop="productTitle"
                    label="商品标题"
                >
                    <el-input
                        v-model="queryFormModel.productTitle"
                        placeholder="请输入商品标题"
                    />
                </el-form-item>
                <el-form-item
                    prop="productCode"
                    label="商品条码"
                >
                    <el-input
                        v-model="queryFormModel.productCode"
                        placeholder="请输入商品条码"
                    />
                </el-form-item>
                <el-form-item
                    prop="marketCodeCatId"
                />
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset1"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <el-container>
                <el-aside width="200px">
                    <el-tree
                        ref="tree"
                        @node-click="clickTree"
                        highlight-current
                        :expand-on-click-node="false"
                        node-key="id"
                        :data="catList"
                        :props="{
                            children: 'children',
                            label: 'name',
                        }"
                    />
                </el-aside>
                <el-main>
                    <!-- 操作 -->
                    <div
                        class="ma-b"
                    >
                        <el-button-group>
                            <el-button
                                type="success"
                                icon="el-icon-plus"
                                size="small"
                                @click="onAdd"
                            >
                                新增
                            </el-button>
                        </el-button-group>
                    </div>

                    <!-- 表格 -->
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="tableData"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="marketCodeCatNamePath"
                            label="批次信息"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.batchName }}{{ scope.row.batchSn }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="marketCodeCatNamePath"
                            label="分类名称"
                            min-width="100"
                        />
                        <el-table-column
                            prop="activityName"
                            label="活动名称"
                            min-width="100"
                        />
                        <el-table-column
                            prop="productBrand"
                            label="商品品牌"
                            min-width="100"
                        />
                        <el-table-column
                            prop="productTitle"
                            label="商品标题"
                            min-width="100"
                        />
                        <el-table-column
                            prop="productCode"
                            label="商品条码"
                            min-width="100"
                        />
                        <el-table-column
                            prop="codeSnPrefix"
                            label="防伪码前缀"
                            width="85"
                        />
                        <el-table-column
                            prop="codeSnBegin"
                            label="防伪码起始"
                            width="85"
                        />
                        <el-table-column
                            prop="applyNum"
                            label="申请数量"
                            width="85"
                        />
                        <el-table-column
                            prop="searchNum"
                            label="已查询数量"
                            width="85"
                        />
                        <el-table-column
                            prop="marketCodeStatusName"
                            label="申请状态"
                            width="70"
                        />
                        <el-table-column
                            prop="remarks"
                            label="备注"
                            min-width="100"
                        />
                        <el-table-column
                            label="创建时间"
                            width="135"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="isAvailable"
                            label="是否可用"
                            width="70"
                        >
                            <template slot-scope="scope">
                                <el-switch
                                    v-model="scope.row.isAvailable"
                                    @input="onIsAvailableInput($event,scope)"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="220"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="exportExcel(scope.row)"
                                    v-if="scope.row.marketCodeStatus === 100"
                                >
                                    导出二维码
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="$router.push('/ts/marketCode/marketCodeSource/'+ scope.row.id)"
                                >
                                    产品溯源
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onTableEdit(scope.row,2)"
                                >
                                    复制
                                </el-button>
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onTableDelete(scope.row.id)"
                                >
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <!-- 分页 -->
                    <div class="ma-t ta-r">
                        <pagination
                            v-model="pagination"
                            @input="onQuery"
                        />
                    </div>
                </el-main>
            </el-container>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="900px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="marketCodeBatchId"
                    label="一物一码批次"
                    label-width="8em"
                >
                    <RemoteSelect
                        remote="/ts/marketCodeBatch/selectByPage"
                        placeholder="请输入"
                        :props="{id:'id',name:'selectName'}"
                        v-model="addDialog.formModel.marketCodeBatchId"
                    />
                </el-form-item>
                <el-form-item
                    prop="marketCodeCatId"
                    label="一物一码分类"
                    label-width="8em"
                >
                    <CascaderPicker
                        :api-class="GoodsCatApi"
                        v-model="addDialog.formModel.marketCodeCatId"
                    />
                </el-form-item>
                <el-form-item
                    label="活动商品"
                    label-width="8em"
                >
                    <div
                        class="ma-b"
                    >
                        <el-button-group>
                            <el-button
                                type="success"
                                icon="el-icon-plus"
                                size="small"
                                @click="onAddSelect()"
                            >
                                选择商品
                            </el-button>
                        </el-button-group>
                    </div>
                </el-form-item>
                <el-form-item
                    prop="codeSnPrefix"
                    label="防伪码前缀"
                    label-width="8em"
                    v-if="!addDialog.formModel.marketCodeBatchId"
                >
                    <el-input
                        v-model="addDialog.formModel.codeSnPrefix"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="codeSnBegin"
                    label="防伪码起始"
                    label-width="8em"
                    v-if="!addDialog.formModel.marketCodeBatchId"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        v-model.number="addDialog.formModel.codeSnBegin"
                    />
                </el-form-item>
                <el-form-item
                    prop="activityName"
                    label="活动名称"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.activityName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="productBrand"
                    label="商品品牌"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.productBrand"
                        auto-complete="off"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    prop="productTitle"
                    label="商品标题"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.productTitle"
                        auto-complete="off"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    prop="productCode"
                    label="商品条码"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.productCode"
                        auto-complete="off"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    prop="batchBeginNum"
                    label="批次开始编号"
                    label-width="8em"
                    v-if="!!addDialog.formModel.marketCodeBatchId"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        :precision="0"
                        v-model.number="addDialog.formModel.batchBeginNum"
                    />
                </el-form-item>
                <el-form-item
                    prop="applyNum"
                    label="申请数量"
                    label-width="8em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        :max="20000000"
                        :precision="0"
                        v-model.number="addDialog.formModel.applyNum"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="8em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="选择商品"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="productTitle"
                    label="商品标题"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.productTitle"
                        placeholder="请输入商品标题"
                    />
                </el-form-item>
                <el-form-item
                    prop="productBrand"
                    label="商品品牌"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.productBrand"
                        placeholder="请输入商品品牌"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                >
                    <el-table-column
                        prop="productTitle"
                        label="商品标题"
                        min-width="100"
                    />
                    <el-table-column
                        prop="productBrand"
                        label="商品品牌"
                        min-width="100"
                    />
                    <el-table-column
                        prop="productCode"
                        label="商品条码"
                        min-width="100"
                    />
                    <el-table-column
                        label="创建时间"
                        min-width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="修改时间"
                        min-width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="90"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="selectRow(scope.row)"
                            >
                                选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'Variable',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                activityName: '', // 活动名称
                productBrand: '', // 商品品牌
                productTitle: '',
                productCode: '',
                marketCodeCatId: '',
                marketCodeBatchInfo: '',
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    marketCodeProductId: '',
                    codeSnPrefix: '',
                    codeSnBegin: '',
                    activityName: '',
                    productBrand: '',
                    productTitle: '',
                    productCode: '',
                    applyNum: 1,
                    remarks: '',
                    marketCodeCatId: '',
                    marketCodeBatchId: '',
                    batchBeginNum: '',
                },
                // 表单校验规则
                formRules: {
                    codeSnBegin: {
                        required: true,
                        message: '请输入',
                        trigger: 'blur',
                    },
                    codeSnPrefix: {
                        required: true,
                        message: '请输入',
                        trigger: 'blur',
                    },
                    activityName: {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur',
                    },
                    productBrand: {
                        required: true,
                        message: '请输入商品品牌',
                        trigger: 'blur',
                    },
                    productTitle: {
                        required: true,
                        message: '请输入商品标题',
                        trigger: 'blur',
                    },
                    productCode: {
                        required: true,
                        message: '请输入商品条码',
                        trigger: 'blur',
                    },
                    applyNum: {
                        required: true,
                        message: '请输入申请数量',
                        trigger: 'blur',
                    },
                    batchBeginNum: {
                        required: true,
                        message: '请输入批次开始编号',
                        trigger: 'blur',
                    },
                    marketCodeCatId: {
                        required: true,
                        message: '请选择分类',
                    },
                },
                type: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    productTitle: '', // 商品标题
                    productBrand: '', // 商品品牌
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            GoodsCatApi: this.$api.Ts.MarketCodeCat,
            catList: [],
            loading: '',
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ts.MarketCode.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
            this.addDialog.type = 1;
        },
        onTableEdit(row, type) {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = row.id;
                formModel.marketCodeProductId = row.marketCodeProductId;
                formModel.codeSnPrefix = row.codeSnPrefix;
                formModel.codeSnBegin = row.codeSnBegin;
                formModel.activityName = row.activityName;
                formModel.productBrand = row.productBrand;
                formModel.productTitle = row.productTitle;
                formModel.productCode = row.productCode;
                formModel.applyNum = row.applyNum;
                formModel.remarks = row.remarks;
                formModel.marketCodeCatId = row.marketCodeCatId;
            });
            this.addDialog.type = type;
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '保存中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Ts.MarketCode.createMarketCode({
                    ...this.addDialog.formModel,
                    isCopy: this.addDialog.type === 2,
                }).then(json => {
                    this.loading.close();
                    const res = json.data;
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                        this.addDialog.isVisible = false;
                    } else {
                        this.$alert(res.msg, '温馨提示', {
                            type: 'error',
                            // callback(action) {},
                        });
                    }
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Ts.MarketCodeProduct.selectByPage({
                ...this.selectDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        selectRow(row) {
            this.addDialog.formModel.marketCodeProductId = row.id;
            this.addDialog.formModel.productBrand = row.productBrand;
            this.addDialog.formModel.productTitle = row.productTitle;
            this.addDialog.formModel.productCode = row.productCode;
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Ts.MarketCode.status({
                id: row.id,
                isAvailable: row.isAvailable,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            }).catch(() => {
                this.$message({
                    message: '操作失败请重试！',
                    type: 'error',
                });
                row.isAvailable = !row.isAvailable;
            });
        },
        //excel导出
        exportExcel(row) {
            this.$http.href('/ts/marketCode/exportExcel', {
                marketCodeId: row.id,
            });
        },
        initCatList() {
            this.$api.Ts.MarketCodeCat.selectAll().then(json => {
                this.catList = this.listToTree(json.data.data);
            });
        },
        listToTree(list, lv) {
            const res = [];
            const parentNodeMap = {};
            let minLv = 9999999;
            list.forEach(item => {
                // item.id 有值时才可能是父节点
                if (item.id) {
                    parentNodeMap[item.id] = item;
                    minLv = lv || (item.lv < minLv ? item.lv : minLv);
                }
            });
            list.forEach(item => {
                if (item.lv === minLv) {
                    res.push(item);
                } else {
                    const parentNode = parentNodeMap[item.parentId];
                    if (parentNode) {
                        parentNode.children = parentNode.children || [];
                        parentNode.children.push(item);
                    } else {
                        console.error('此节点数据有问题', item);
                    }
                }
            });
            this.$utils.Convert.sortTreeData(res);
            return res;
        },
        clickTree(data) {
            this.queryFormModel.marketCodeCatId = data.id;
            this.onQuery();
        },
        onReset1() {
            this.$refs.tree.setCurrentKey(null);
            this.onReset();
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '删除中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Ts.MarketCode.delete({ ids }).then(json => {
                    this.loading.close();
                    const res = json.data;
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    } else {
                        this.$alert(res.msg, '温馨提示', {
                            type: 'error',
                            // callback(action) {},
                        });
                    }
                });
            });
        },
    },
    created() {
        this.initCatList();
    },
};
</script>

<style lang="scss">
</style>
